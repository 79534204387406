.wrapper {
  display: grid;
  background-color: #ffffff;
  min-height: 100vh;
}

.wrapper.one-column {
  grid-template-columns: 1fr;
}

.wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

.iframe-logout {
  margin-left: 100%;
  width: 100%;
}

@media only screen and (min-width: 375px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .wrapper .iframe-container {
    display: none;
  }

  #okta-sign-in.auth-container .auth-content {
    padding: 60px 0 20px;
  }
}

@media only screen and (min-width: 835px) {
  #okta-sign-in.auth-container .auth-content {
    padding: 60px 42px 20px;
  }

  #okta-sign-in.auth-container .auth-content:has(.password-reset) {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1195px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .wrapper .iframe-container {
    display: flex;
  }
}
